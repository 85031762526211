// File: notifications.effects.ts
import {Injectable} from '@angular/core';
import {NotificationService} from '@core/services/notification/notification.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, from, map, mergeMap, of} from 'rxjs';
import {addNotification, getUserNotifications, getUserNotificationsFailure, getUserNotificationsSuccess, getUserUnreadNotifications, getUserUnreadNotificationsFailure, getUserUnreadNotificationsSuccess, markNotificationAsRead} from './notifications.actions';

@Injectable()
export class NotificationsEffects {

  /**
   * Constructor
   *
   * @param actions$ the Actions observable
   * @param _notificationService the NotificationService
   */
  constructor(
    private actions$: Actions,
    private _notificationService: NotificationService,
  ) { }

  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserNotifications),
      mergeMap((action) => {
        return from(this._notificationService.getUserNotifications(action.filters, action.paginate))
          .pipe(
            map(
              (result) => getUserNotificationsSuccess({notifications: result})),
            catchError(error => of(getUserNotificationsFailure({error})))
          )
      })
    )
  )


  loadUnreadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserUnreadNotifications),
      mergeMap((action) => from(this._notificationService.getUserNotifications(action.filters, action.paginate))
        .pipe(
          map(
            (result) => getUserUnreadNotificationsSuccess({notifications: result})),
          catchError(error => of(getUserUnreadNotificationsFailure({error}))))
      )
    )
  )
  addNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNotification),
      map(() => {
        return {type: '[notifications] Add Notification'};
      })
    )
  );

  markNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markNotificationAsRead),
      map((action) => {
        this._notificationService.markUserNotificationAsRead(action.notificationId);
        return {type: '[notifications] Mark Notification As Read'};
      })
    )
  );
}

