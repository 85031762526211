import {Error} from '@core/domain/api/api-error-response';
import {IUserNotificationResponse, IUserNotifications} from '@core/domain/messages/message-notifications';
import {createReducer, on} from '@ngrx/store';
import {addNotification, getUserNotifications, getUserNotificationsFailure, getUserNotificationsSuccess, getUserUnreadNotifications, getUserUnreadNotificationsFailure, getUserUnreadNotificationsSuccess, markNotificationAsRead} from './notifications.actions';

export interface NotificationsState {
  notifications: IUserNotificationResponse;
  unreadNotifications: IUserNotificationResponse;
  unreadNotificationsCount: number;
  notificationsloaded: boolean;
  unreadNotificationsloaded: boolean;
  loading: boolean;
  error: Error | null;
}

export const initialNotificationState: NotificationsState = {
  notifications: {
    notifications: [] as IUserNotifications[],
    total: 0
  },
  unreadNotifications: {
    notifications: [] as IUserNotifications[],
    total: 0
  },
  unreadNotificationsCount: 0,
  notificationsloaded: false,
  unreadNotificationsloaded: false,
  loading: false,
  error: null,
};

export const notificationsReducer = createReducer(
  initialNotificationState,
  on(getUserNotifications, (state) => ({...state, loading: true})),

  on(getUserNotificationsSuccess, (state, {notifications}) => ({
    ...state,
    notifications: {
      notifications: [...notifications.notifications],
      total: notifications.total
    },
    loading: true,
    notificationsloaded: true
  })),

  on(getUserNotificationsFailure, (state, {error}) => ({...state, error, loading: false})),

  on(getUserUnreadNotifications, (state) => ({...state, loading: true})),

  on(getUserUnreadNotificationsSuccess, (state, {notifications}) => ({
    ...state,
    unreadNotifications: {
      notifications: [...notifications.notifications],
      total: notifications.total
    },
    unreadNotificationsCount: notifications.total,
    loading: true,
    unreadNotificationsloaded: true
  })),

  on(getUserUnreadNotificationsFailure, (state, {error}) => ({...state, error, loading: false})),

  on(addNotification, (state, {notification}) => {
    const existingNotification = state.unreadNotifications.notifications.find(n => n.id === notification.id);
    if (existingNotification) {
      return state;
    }
    return {
      ...state,
      notifications: {
        notifications: [notification, ...state.notifications.notifications],
        total: state.notifications.total
      },
      unreadNotifications: {
        notifications: [notification, ...state.unreadNotifications.notifications],
        total: state.unreadNotifications.total + 1
      },
      unreadNotificationsCount: state.unreadNotificationsCount + 1
    };
  }),

  on(markNotificationAsRead, (state, {notificationId}) => {
    const unreadNotifications = state.unreadNotifications.notifications.filter(notification => notification.id !== notificationId);
    const notifications = state.notifications.notifications.map(notification =>
      notification.id.toString() === notificationId.toString() ? {...notification, isRead: true} : notification
    );
    return {
      ...state,
      unreadNotifications: {
        notifications: unreadNotifications,
        total: state.unreadNotifications.total - 1
      },
      unreadNotificationsCount: state.unreadNotificationsCount - 1,
      notifications: {
        notifications: notifications,
        total: state.notifications.total
      }
    };
  })
);
