import {Error} from '@core/domain/api/api-error-response';
import {IChatNotification, INotificationResponse} from '@core/domain/messages/message-notifications';
import {createReducer, on} from '@ngrx/store';
import {
  addChatNotification,
  addChatNotificationFailure,
  addChatNotificationSuccess,
  getUserChatsNotifications,
  getUserChatsNotificationsFailure,
  getUserChatsNotificationsSuccess,
  getUserUnreadChatsNotifications,
  getUserUnreadChatsNotificationsFailure,
  getUserUnreadChatsNotificationsSuccess,
  markChatNotificationAsRead,
  markChatNotificationAsReadFailure,
  markChatNotificationAsReadSuccess
} from './chat-notifications.actions';

export interface ChatNotificationsState {
  unreadChatNotificationsCount: number;
  chatNotifications: INotificationResponse;
  unreadChatNotifications: INotificationResponse;
  chatNotificationsLoaded: boolean;
  chatUnreadNotificationsLoaded: boolean;
  loading: boolean;
  error: Error | null;
}

export const initialChatNotificationState: ChatNotificationsState = {
  unreadChatNotificationsCount: 0,
  chatNotifications: {
    messages: [] as IChatNotification[],
    total: 0
  },
  unreadChatNotifications: {
    messages: [] as IChatNotification[],
    total: 0
  },
  chatNotificationsLoaded: false,
  chatUnreadNotificationsLoaded: false,
  loading: false,
  error: null,
};

export const chatNotificationsReducer = createReducer(
  initialChatNotificationState,
  on(getUserChatsNotifications, (state) => ({...state, loading: true})),

  on(getUserChatsNotificationsSuccess, (state, {chatNotifications}) => ({
    ...state,
    chatNotifications: {
      messages: [...chatNotifications.messages],
      total: chatNotifications.total
    },
    loading: false,
    chatNotificationsLoaded: true
  })),

  on(getUserChatsNotificationsFailure, (state, {error}) => ({...state, error, loading: false})),

  on(getUserUnreadChatsNotifications, (state) => ({...state, loading: true})),

  on(getUserUnreadChatsNotificationsSuccess, (state, {chatUnreadNotifications}) => ({
    ...state,
    unreadChatNotifications: {
      messages: [...chatUnreadNotifications.messages],
      total: chatUnreadNotifications.total
    },
    unreadChatNotificationsCount: chatUnreadNotifications.total,
    loading: false,
    chatUnreadNotificationsLoaded: true,
  })),

  on(getUserUnreadChatsNotificationsFailure, (state, {error}) => ({...state, error, loading: false})),

  on(addChatNotification, (state) => ({...state, loading: true})),

  on(addChatNotificationSuccess, (state, {notification}) => {
    if (notification === undefined) {
      console.error('Received undefined notification');
    }
    const existingChatNotification = state.chatNotifications.messages.find(n => n.id === notification.id);
    const existingUnreadNotification = state.unreadChatNotifications.messages.find(n => n.id === notification.id);

    let updatedChatMessages = [...state.chatNotifications.messages];
    let updatedUnreadMessages = [...state.unreadChatNotifications.messages];
    let unreadChatNotificationsCount = state.unreadChatNotificationsCount;

    if (existingChatNotification) {
      // update existing notification in chatNotifications
      updatedChatMessages = updatedChatMessages.filter(n => n.id !== notification.id);
      updatedChatMessages.unshift(notification);
    } else {
      // update existing notification in chatNotifications
      updatedChatMessages.unshift(notification);
    }

    if (existingUnreadNotification) {
      // update existing notification in unreadChatNotifications
      updatedUnreadMessages = updatedUnreadMessages.filter(n => n.id !== notification.id);
      updatedUnreadMessages.unshift(notification);
    } else {
      // add new notification to unreadChatNotifications and increment unreadChatNotificationsCount
      updatedUnreadMessages.unshift(notification);
      unreadChatNotificationsCount = updatedUnreadMessages.length
    }

    return {
      ...state,
      chatNotifications: {
        messages: updatedChatMessages,
        total: updatedChatMessages.length
      },
      unreadChatNotifications: {
        messages: updatedUnreadMessages,
        total: updatedUnreadMessages.length
      },
      unreadChatNotificationsCount: unreadChatNotificationsCount
    };
  }),

  on(addChatNotificationFailure, (state, {error}) => ({...state, error, loading: false})),

  on(markChatNotificationAsRead, (state) => ({...state, loading: true})),

  on(markChatNotificationAsReadSuccess, (state, {notification}) => {
    const unreadChatNotifications = state.unreadChatNotifications.messages.filter(n => n.id !== notification.id);
    const chatNotifications = state.chatNotifications.messages.map(n =>
      n.id.toString() === notification.id.toString() ? {...n, isRead: true} : n
    );
    return {
      ...state,
      unreadChatNotifications: {
        messages: unreadChatNotifications,
        total: state.unreadChatNotifications.total - 1
      },
      unreadChatNotificationsCount: state.unreadChatNotificationsCount - 1,
      chatNotifications: {
        messages: chatNotifications,
        total: state.chatNotifications.total
      }
    };
  }),

  on(markChatNotificationAsReadFailure, (state, {error}) => ({...state, error, loading: false}))
);
