import {Injectable} from '@angular/core';
import {NotificationService} from '@core/services/notification/notification.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, from, map, mergeMap, of} from 'rxjs';
import {addChatNotification, addChatNotificationFailure, addChatNotificationSuccess, getUserChatsNotifications, getUserChatsNotificationsFailure, getUserChatsNotificationsSuccess, getUserUnreadChatsNotifications, getUserUnreadChatsNotificationsFailure, getUserUnreadChatsNotificationsSuccess, markChatNotificationAsRead, markChatNotificationAsReadFailure, markChatNotificationAsReadSuccess} from './chat-notifications.actions';

@Injectable()
export class ChatNotificationsEffects {

  /**
   * Constructor
   *
   * @param actions$ the Actions observable
   * @param _notificationService the NotificationService
   */
  constructor(
    private actions$: Actions,
    private _notificationService: NotificationService,
  ) { }

  loadChatNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserChatsNotifications),
      mergeMap((action) => {
        return from(this._notificationService.getChatNotifications(action.filters, action.paginate))
          .pipe(
            map(
              (result) => getUserChatsNotificationsSuccess({chatNotifications: result})),
            catchError(error => of(getUserChatsNotificationsFailure({error})))
          )
      })
    )
  )


  loadUnreadChatNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserUnreadChatsNotifications),
      mergeMap((action) => from(this._notificationService.getChatNotifications(action.filters, action.paginate))
        .pipe(
          map(
            (result) => getUserUnreadChatsNotificationsSuccess({chatUnreadNotifications: result})),
          catchError(error => of(getUserUnreadChatsNotificationsFailure({error}))))
      )
    )
  )

  addChatNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addChatNotification),
      mergeMap((action) => {
        // Simulate an async operation, replace with actual service call if needed
        return of(action.notification).pipe(
          map((notification) => {
            if (!notification) {
              console.error('Received undefined notification');
              return addChatNotificationFailure({error: {error: {status: 500, message: 'Received undefined notification'}}});
            }
            return addChatNotificationSuccess({notification});
          }),
          catchError(error => of(addChatNotificationFailure({error})))
        );
      })
    )
  );

  markChatNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markChatNotificationAsRead),
      mergeMap((action) => {
        return from(this._notificationService.markChatNotificationAsReadByChatId(action.notificationChatId))
          .pipe(
            map((updatedNotification) => markChatNotificationAsReadSuccess({notification: updatedNotification})),
            catchError(error => of(markChatNotificationAsReadFailure({error})))
          )
      })
    )
  );
}

